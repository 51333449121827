.projects-section {
    min-height: 60vh;
    margin-top: 10vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    /* align-items: center; */
    justify-content: center;
    color:white;
    /* font-family: "Couerier Prime", monospace; */
    font-family: "News Cycle", serif;
}

.projects-about {
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    min-width: 50%;
    min-height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.projects-accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50%;
    min-height: 100%;
}

.projects-accordion-wrapper {
    display: flex;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid green;
}

.projects-about-heading {
    /* text-decoration: underline;  */
    color: green;
    font-size: 5vw;
    margin-bottom: 5%;
    /* font-size: x-large; */
    font-size: 5vw;
}

.projects-about-body {
    font-size: x-large;
    padding: 0 10%;
}

@media screen and (max-width: 600px) {
    .projects-section {
        flex-direction: column;
        margin-top: 0;
    }

    .projects-about {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .projects-about-heading {
        font-size: 15vw;
    }

    .projects-about-body {
        font-size: large;
    }
    

}