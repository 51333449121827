#skilltable-body {
    border: 1px solid green;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    height: fit-content;
    /* margin-left: 2rem; */
    /* margin-right: 2rem; */
}

.skilltable-content {
    width: 100%;
    display: flex;
    flex-direction: row;

}

.skilltable-content-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* justify-content: center; */
}

.skilltable-content-header {
    width: 100%;
    display: flex;
    font-size: x-large;
    border: 1px solid green;
    border-top: 0px;
    justify-content: center;
}

.first {
    border-top: 0px;
}

.skilltable-content-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    font-size: x-large;
}

@media screen and (max-width: 600px) {
    .skilltable-content {
        flex-direction: column;
    }

    .skilltable-content-header {
        border-top: 1px solid green;
        border-left: 0px;
        border-right: 0px;
        font-size: large;
    }

    .first {
        border-top: 0px;
    }

    .skilltable-content-list {
        font-size: large;
    }
}