.container {
    vertical-align: center;
    min-width: 100%;
    height: auto;
    /* font-family: "Courier Prime", monospace; */
    font-family: "News Cycle", serif;
}

.wrapper {
    border: 1px solid green;
    overflow: hidden;
}

.wrapper .project-name-container {
    width: 100%;
    text-align: left;
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.wrapper .project-name-container.active {
    /* color:green; */
}

.wrapper .about-project-container {
    color:white;
    padding: 0 1rem;
    transition: height .2s ease-in-out;
}
  
.wrapper .project-name-content {
    /* font-family: "Courier Prime", monospace; */
    width: 80%;
    font-family: "News Cycle", serif;
}

.wrapper .project-name-dropdown { 
    width: 20%;
    /* padding: 1rem; */
}

.wrapper .dropdown-turned {
    transform: rotate(-90deg);
    transition: transform 0.4s ease-in-out;
}

.wrapper .dropdown-not-turned {
    /* Shouldn't be shown due to splash animation  */
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
}

.wrapper .about-project-content {
    padding: 1rem 0;
    font-size: 20px;
    font-style: italic;
}

.wrapper .github-project-link {
    font-size: 1.3rem;
    padding: 1rem 0;
    color: lightblue;
}

span {
    color:white;
}