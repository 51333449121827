.navbar-container {
    height: 4vh;
    max-width: 100vw;
    border-bottom: 1px solid green;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 18;
    background-color: black;
    transition: top 0.3s;
}

.logo-text {
    width: 20vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
    align-items: center;
    /* background-color: green; */
}

.logo-text-pushback {
    width: 20vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
    align-items: center;
    /* background-color: green; */
}

.nav-buttons {
    width: 85%;
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    /* font-family: "Courier Prime", monospace; */
    font-family: "News Cycle", serif;
    /* background-color: blue; */
}

.nav-button {
    cursor: pointer;
    display: flex;
}

.nav-button:hover {
    /* text-decoration: underline; */
    animation: color-changing 1s infinite;
    cursor: pointer;
}

@keyframes color-changing {
    0% {
        color: white;
    }

    50% {
        color: green;
    }

    100% {
        color: white;
    }
}

.logo-text span {
    font-size: large;
}

.logo-image {
    width: 3rem;
    height: 3rem;
    /* border: 1px solid white; */
    border-radius: 10px;
    /* margin-right: 2%; */
    animation: rotate 1s ease 1s 1;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.mobile-dropdown {
    width: 3rem;
    height: 3rem;
    /* border: 1px solid white; */
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    display: none;
}

.mobile-dropdown.rotate {
    transform: rotate(90deg);
}

.mobile-nav-buttons {
    overflow: hidden;
    border: 1px solid green;
    max-height: 0;
    display: flex;
    flex-direction: column;
    transition: max-height 0.4s ease-in-out;
    background-color: black;
    position: absolute;
    left: 0;
    top: calc(4vh + 2rem);
    /* border: 1px solid white; */
    min-width: calc(100% - 2px);

}

.mobile-nav-buttons.show {
    max-height: 100vh;
    height: auto;
}

.mobile-nav-button {
    padding: 2%;
    color: white;
    text-decoration: none;
    font-family: "News Cycle", serif;
}

.mobile-nav-button:hover {
    /* text-decoration: underline; */
    animation: color-changing 1s infinite
}


.hidden {
    display: none;
}

@media screen and (max-width: 600px) {
    .navbar-container {
        display: flex;
        justify-content: space-between;
    }

    .mobile-dropdown {
        display: flex;
    }

    .nav-buttons {
        display: none;
    }

    .logo-text-pushback {
        display: none;
    }
}