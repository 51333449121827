.about-section {
    min-height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    /* align-items: center; */
    justify-content: center;
    color:white;
    /* font-family: "Courier Prime", monospace; */
    font-family: "News Cycle", serif;
}

.about-container {
    margin-top: 10rem;
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-heading {
    /* text-decoration: underline; */
    color: green;
    font-size: 4rem;
    margin-bottom: 5%;
}

.about-body {
    width: 80%;
    font-size: xx-large;
}


/* .table-header {
    color: green;
} */

.skills-container {
    margin-top: 10rem;
    min-width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
}

.skills-table {
    border: 1px solid green;
    width: 100%;
    text-align: left;
    align-items: center;
    font-size: xx-large;
}

.skills-table th {
    width: 30%;
    border: 1px solid white;
    border-top: hidden;
    text-align: center;
}

.skills-table td {
    vertical-align: top;
}

@media screen and (max-width: 600px) {
    /* .about-section {
        flex-direction: column;
    } */

    .skills-container {
        margin-top: 0;
    }

    .about-container {
        margin: 1rem 0rem;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    
    /* .about-heading {
        font-size: 15vw;
    } */

    .about-body {
        font-size: large;
    }

    .skills-container {
        margin-top: 2rem;
    }

    .skills-body {
        min-width: 100vw;
        padding: 0;
    }

    .skills-table td{
        /* min-width: 10vw; */
        font-size: 3vw;
    }
}

@media screen and (max-width: 1376px) {
    .about-section {
        flex-direction: column;
    }
}