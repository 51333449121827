#work-section {
    min-height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:white;
    font-family: "News Cycle", serif;
}

.work-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.work-heading {
    color: green;
    font-size: 4rem;
    margin-bottom: 5%;
}

.work-body-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


