.contact-section {
    margin-top: 2rem;
    min-height: auto;
    width: 100vw;
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    /* align-items: center; */
    justify-content: center;
    /* color: black; */
    /* background-color: white; */
    /* font-family: "Courier Prime", monospace; */
    font-family: "News Cycle", serif
}

.contact-container {
    width: 100%;
    display: flex;
    background-color: green;
    justify-content: center;
    /* background-color: green; */
    color: white;
    text-align: center;
}

.contact-heading {
    font-size: 5vw;
    /* text-decoration: underline; */
    color: green;
}

.list-of-contacts {
    padding: 5%;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    text-align: left;
}

.contact-name {
    display: flex;
    align-items: center;
    font-size: x-large;
    /* justify-content: space-around; */

}

.contact-image {
    margin: 0.5rem 0rem;
    height: 4rem;
    width: auto;
}

.contact-image-github {
    margin: 0.5rem 0rem;
    height: 4rem;
    width: auto;
    background-color: white;
    border-radius: 35px;
}

.contact-text {
    margin: 1rem 1rem;
}

.contact-link {
    color: lightblue
}

.section-of-contacts {
    width: 40%;
    gap: 1rem;
    height: 10rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.contact {
    cursor: pointer;
}

.contact-visual {
    width: 3rem;
    height: 3rem;
    color: black
}

.email:hover {
    position: relative;
    animation: bounce 0.8s;
}

.phone:hover {
    animation: shake 0.2s infinite;
}

.github:hover {
    animation: spin 1s;
}

.linkedin:hover {
    animation: punch 1s;
}

.twitter:hover {
    animation: pop 1s;
}

@keyframes pop {
    0% { scale: 100%;}
    20% { scale: 140%;}
}


@keyframes punch {
    0% {
        transform: rotate3d(0);
    }

    50% {
        transform: rotate3d(1, 0.5, 0.5, 180deg);
    }

    100% {
        transform: rotate3d(1, 1, 0.5, 360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate3d(0);
    }

    50% {
        transform: rotate3d(0, 1, 0, 180deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
}

@keyframes bounce {
    0% {
        top: 0
    }

    ;

    15% {
        top: -1rem;
    }

    ;

    35% {
        top: 0;
    }

    ;

    40% {
        top: -0.5rem;
    }

    ;

    100% {
        top: 0;
    }

    ;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-15deg)
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(15deg);
    }
}

@media screen and (max-width: 600px) {
    .contact-heading {
        font-size: 15vw;
    }

    .contact-name {
        font-size: 4vw;
    }

    .contact-image {
        width: 10vw;
        height: 10vw;
    }

    .contact-image-github {
        width: 10vw;
        height: 10vw;
    }

    .contact-text {
        font-size: medium;
    }

    .contact-link {
        font-size: small;
    }

    .contact-visual {
        width: 2rem;
        height: 2rem;
    }
}