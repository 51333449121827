.splash {
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center; 
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    animation: slideOut 2s 2s forwards;
}

.splash::-webkit-scrollbar {
    display: none
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.splash-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.splash-image {
    height: 5rem;
    border: 2px transparent solid;
    border-radius: 20px;
    animation: popIn 0.3s ease-out forwards;
    /* transform-origin: center; */
    z-index: 4;
}

.splash-text {
    display: flex;
    text-wrap: nowrap;
    width: auto;
    max-width: 0;
    align-items: center;
    /* background-color: red; */
    /* font-family: 'Courier Prime', monospace;
     */
     
    font-family: "News Cycle", serif;
    /* padding-left: 8px; */
    font-size: 2rem;
    overflow-x: hidden;
    /* opacity: 0; */
    animation: revealText 1s 0.5s forwards;
}

/* Animation for popping in */
@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    80% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


@keyframes revealText {
    0% {
        max-width: 0;
        /* opacity: 0; */
    }
    100% {
        max-width: 50vw;
        /* opacity: 1; */
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 600px) {
    .splash-image {
        height: 3rem;
    }

    .splash-text {
        font-size: 1rem;
    }
}